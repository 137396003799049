import PropTypes from 'prop-types';
import React, { Component } from 'react';

import onClickOutside from 'react-onclickoutside';

export class AppSelect extends Component {
  state = {
    showingOptions: false,
  };

  toggleShowApps = () => {
    this.setState({
      ...this.state,
      showingOptions: !this.state.showingOptions,
    });
  };

  handleClickOutside = () => {
    this.setState({ ...this.state, showingOptions: false });
  };

  appSelected = (appName) => {
    this.props.goToApp(appName);
  };

  render() {
    const menuStyle = this.state.showingOptions ? { display: 'block' } : { display: 'none' };
    return (
      <div className="btn-group pull-right">
        <div className="btn-group">
          <span className="badge badge-primary selectable" onClick={this.toggleShowApps}>
            <i className="fa fa-caret-down" /> Sales & Reporting
          </span>
          <ul className="dropdown-menu" style={menuStyle}>
            <li className="dropdown-header">Switch Apps</li>
            <li className="selectable">
              <div
                className="selectable"
                style={{
                  padding: '7px 12px',
                }}
                tabIndex="-1"
                onClick={() => this.appSelected('Marketing')}
              >
                Marketing
              </div>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}

AppSelect.propTypes = {
  goToApp: PropTypes.func.isRequired,
};

export default onClickOutside(AppSelect);
