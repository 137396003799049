import config from 'config';
import 'whatwg-fetch';

import store from 'store/store';
import session from 'modules/Stores/Session';
import Bugsnag from '@bugsnag/js';
import Pusher from 'pusher-js';

// ------------------------------------
// Constants
// ------------------------------------
export const GET = 'GET';
export const POST = 'POST';
export const PUT = 'PUT';
export const PATCH = 'PATCH';
export const DELETE = 'DELETE';

export function request(endpoint, type, data, successCallback, failureCallback, additionalHeaders = {}) {
  rawRequest(config.apiHost, endpoint, type, data, successCallback, failureCallback, additionalHeaders);
}

export function rawRequest(host, endpoint, type, data, successCallback, failureCallback, additionalHeaders = {}) {
  const fullEndpoint = host + '/' + endpoint;
  const jsonBody = data ? JSON.stringify(data) : undefined;

  function parseJSON(response) {
    if (response.ok) {
      return response.json().then(function (payload) {
        successCallback(payload);
      });
    } else {
      return response.json().then(function (errors) {
        failureCallback(errors);
      });
    }
  }

  function handleException(exception) {
    if (exception) {
      const error = { server: exception.message };
      failureCallback({ errors: error });
      Bugsnag.notify(exception);
    }
  }

  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'Client-ID': config.appName,
    ...additionalHeaders,
  };

  let requestData = {
    headers: headers,
    method: type,
    credentials: 'include',
  };
  if (jsonBody) {
    requestData = { ...requestData, body: jsonBody };
  }

  fetch(fullEndpoint, requestData)
    .then(parseJSON)
    .then(handleException)
    .catch(function (exception) {
      Bugsnag.notify(exception);
    });
}

export function rawThirdPartyRequest(host, endpoint, type, data, successCallback, failureCallback) {
  const fullEndpoint = host + '/' + endpoint;
  const jsonBody = data ? JSON.stringify(data) : undefined;

  function parseJSON(response) {
    if (response.ok) {
      return response.json().then(function (payload) {
        successCallback(payload);
      });
    } else {
      return response.json().then(function (errors) {
        failureCallback(errors);
      });
    }
  }

  function handleException(exception) {
    if (exception) {
      const error = { server: exception.message };
      failureCallback({ errors: error });
      Bugsnag.notify(exception);
    }
  }

  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };

  let requestData = {
    headers: headers,
    method: type,
  };
  if (jsonBody) {
    requestData = { ...requestData, body: jsonBody };
  }

  fetch(fullEndpoint, requestData)
    .then(parseJSON)
    .then(handleException)
    .catch(function (exception) {
      Bugsnag.notify(exception);
    });
}

export function buildIndexUrl(base, page, sort, search) {
  let url = base + '?page[number]=' + page;
  if (sort) {
    url = url + '&sort=' + sort;
  }
  if (search) {
    url = url + '&search=' + search;
  }
  return url;
}

export function createWebsocketConsumer() {
  if (window.gymleads_ws_consumer) {
    return window.gymleads_ws_consumer;
  }

  Pusher.logToConsole = config.debug;
  const state = store.getState();
  const realtimeAuth = session.selectors.getRealtimeAuth(state);
  if (!realtimeAuth || !realtimeAuth.api_key) {
    store.dispatch(session.actions.endSession());
    return;
  }

  const headers = {
    Authorization: 'Bearer ' + realtimeAuth.realtime_auth_token,
    'Client-ID': 'ReactApp',
  };

  window.gymleads_ws_consumer = new Pusher(realtimeAuth.api_key, {
    cluster: realtimeAuth.api_cluster,
    authEndpoint: config.apiHost + '/' + realtimeAuth.auth_endpoint,
    auth: {
      headers: headers,
    },
    secure: true,
  });
  return window.gymleads_ws_consumer;
}

export function closeWebsocketConsumer() {
  if (!window.gymleads_ws_consumer) {
    return;
  }
  window.gymleads_ws_consumer.disconnect();
  window.gymleads_ws_consumer = null;
}
