import { createSelector } from 'reselect';
import session from 'modules/Stores/Session';

import STRINGS from 'strings';

import * as settings from './routes';

export function linksForUserRoleFactory(userRoleSelector, permissionsSelector) {
  const Strings = STRINGS.Settings;
  return createSelector([userRoleSelector, permissionsSelector], (userRole, permissions) => {
    let links = [
      {
        to: `/secure/settings/${settings.Profile.constants.NAME}`,
        style: `si si-user`,
        title: Strings.Profile.title,
      },
      {
        to: `/secure/settings/${settings.CommunicationPreferences.constants.NAME}`,
        style: `si si-envelope-letter`,
        title: Strings.CommunicationPreferences.title,
      },
      {
        to: `/secure/settings/${settings.Calendar.constants.NAME}`,
        style: `fa fa-calendar`,
        title: Strings.Calendar.title,
      },
    ];

    if (session.helpers.userRoleHasOwnerPermissions(userRole)) {
      links.push({
        to: `/secure/settings/${settings.Company.constants.NAME}`,
        style: `fa fa-institution`,
        title: Strings.CompanyAccount.title,
      });
      links.push({
        to: `/secure/settings/${settings.SecurityPolicy.constants.NAME}`,
        style: `fa fa-lock`,
        title: Strings.SecurityPolicy.title,
      });
      links.push({
        to: `/secure/settings/${settings.EmailDomain.constants.NAME}`,
        style: `si si-envelope`,
        title: Strings.EmailDomain.title,
      });
      links.push({
        to: `/secure/settings/${settings.Tags.constants.NAME}`,
        style: `si si-tag`,
        title: Strings.Tags.title,
      });
      links.push({
        to: `/secure/settings/${settings.CallSchedule.constants.NAME}`,
        style: `si si-call-out`,
        title: Strings.CallSchedules.title,
      });
      links.push({
        to: `/secure/settings/${settings.Regions.constants.NAME}`,
        style: `fa fa-map`,
        title: Strings.Regions.title,
      });
      links.push({
        to: `/secure/settings/${settings.CustomMailMergeTags.constants.NAME}`,
        style: `si si-tag`,
        title: 'Custom Mail Merge Tags',
      });
    }

    if (session.helpers.userRoleHasManagerPermissions(userRole)) {
      links.push({
        to: `/secure/settings/${settings.Locations.constants.NAME}`,
        style: `fa fa-building-o`,
        title: Strings.Locations.title,
      });

      links.push({
        to: `/secure/settings/${settings.SalesTeams.constants.NAME}`,
        style: `fa fa-user`,
        title: Strings.SalesTeams.title,
      });
      links.push({
        to: `/secure/settings/${settings.ThirdPartyIntegrations.constants.NAME}`,
        style: `fa fa-plug`,
        title: `Third Party Integrations`,
      });
      links.push({
        to: `/secure/settings/${settings.AutomaticReports.constants.NAME}`,
        style: `fa fa-table`,
        title: `Automatic Reports`,
      });
      links.push({
        to: `/secure/settings/${settings.BulkLeadReallocations.constants.NAME}`,
        style: `fa fa-mail-forward`,
        title: `Lead Reallocations`,
      });
      links.push({
        to: `/secure/settings/${settings.FrontDesk.constants.NAME}`,
        style: `fa fa-wpforms`,
        title: Strings.FrontDesk.title,
      });
    }

    if (session.helpers.userRoleHasOwnerPermissions(userRole)) {
      links.push({
        to: `/secure/settings/${settings.SalesTargets.constants.NAME}`,
        style: `si si-target`,
        title: Strings.SalesTargets.title,
      });
      links.push({
        to: `/secure/settings/${settings.LeadSettings.constants.NAME}`,
        style: `fa fa-users`,
        title: `Lead Settings`,
      });
      links.push({
        to: `/secure/settings/${settings.SalesFunnel.constants.NAME}`,
        style: `fa fa-filter`,
        title: `Sales Funnel`,
      });
      links.push({
        to: `/secure/settings/${settings.Trials.constants.NAME}`,
        style: `fa fa-clock-o`,
        title: `Trials`,
      });
      links.push({
        to: `/secure/settings/${settings.Deal.constants.NAME}`,
        style: `fa fa-dollar`,
        title: `Deals`,
      });
      links.push({
        to: `/secure/settings/lawfulPro${settings.LawfulProcessingBasis.constants.NAME}cessingBasis`,
        style: `fa fa-legal`,
        title: `Lawful Processing Bases`,
      });
    }

    if (session.helpers.userRoleHasFranchiseOwnerPermissions(userRole)) {
      links.push({
        to: `/secure/settings/${settings.EmailMaps.constants.NAME}`,
        style: `si si-paper-plane`,
        title: `Email To Lead Maps`,
      });
      links.push({
        to: `/secure/settings/${settings.ImportLeads.constants.NAME}`,
        style: `glyphicon glyphicon-import`,
        title: `Import`,
      });
      links.push({
        to: `/secure/settings/${settings.Billing.constants.NAME}`,
        style: `si si-credit-card`,
        title: `Billing`,
      });
      links.push({
        to: `/secure/settings/${settings.SMSRecharge.constants.NAME}`,
        style: `fa fa-mobile`,
        title: `SMS Accounts`,
      });
    }
    return links;
  });
}
